:root {
  --py-40: 20px;
  --py-20: 10px;
  --tech-cols: 1;
  --tech-gap: 10px;
}

html, body {
  height: 100%;
  margin: 0;
}

a {
  transition: all .3s ease;
}

.webswitcher {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
}
.webswitcher a {
  color: #000000;
  font-weight: 600;
  font-size: 1.5rem;
  text-decoration: none;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.webswitcher a:hover {
  text-decoration: none;
  opacity: 1;
}
.locale {
  left: unset;
  right: 10px;
  cursor: pointer;
}

.webswitcher.locale a.locale-active {
  opacity: .6;
  font-weight: 700;
}
.webswitcher.locale a.locale-active:hover {
  opacity: 1;
}
.locale-divider {
  font-size: 1.5rem;
  opacity: 0.5;
}

.mainmenu {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.menuitem h1 {
  color: #f5f5f5;
  letter-spacing: 0;
  text-transform: uppercase;
  transition: all 300ms ease;
  font-family: "bebas-kai", sans-serif;
  font-size: 4em;
  cursor: pointer;
  text-shadow: 0 0 8px #00000044;
  width: 100%;
}
.menuitem.toggled h1 {
  font-size: 2.5em;
  letter-spacing: .1em;
  margin-top: 20px;
  position: relative;
  width: min-content;
}
.menuitem:not(.toggled):hover h1 {
  letter-spacing: .4em;
  text-indent: .4em;
  position: relative;
  width: min-content;
}
.menuitem.toggled h1::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 10px solid #fff;
  filter: drop-shadow(0 0 8px #00000055);
}
.menuitem.toggled h1::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #fff;
  filter: drop-shadow(0 0 8px #00000055);
}
.menuitem:not(.toggled):hover h1::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 20px solid #fff;
  filter: drop-shadow(0 0 8px #00000055);
}
.menuitem:not(.toggled):hover h1::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 20px solid #fff;
  filter: drop-shadow(0 0 8px #00000055);
}
.menuitem {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  flex-grow: 1;
  -webkit-box-shadow: inset 0 3px 5px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0 3px 5px -3px rgba(0,0,0,0.75);
  box-shadow: inset 0 3px 5px -3px rgba(0,0,0,0.75);
  min-height: 10vh;
  max-height: 100vh;
}
.menuitem:hover {
  flex-grow: 1.15;
}

.menuitem.toggled {
  flex-grow: 10;
}
.submenu {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.submenu-item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .3s ease;
  box-shadow: inset 3px 0 5px -3px rgba(0,0,0,0.75),
              inset 0 3px 5px -3px rgba(0,0,0,0.75);
  max-width: 100vw;
  min-width: 10vw;
}
.submenu-item:hover {
  flex-grow: 1.15;
}
.submenu-item.toggled {
  flex-grow: 15;
  min-width: 100vw;
}
.submenu-item h2 {
  color: #f5f5f5;
  letter-spacing: 0;
  text-transform: uppercase;
  transition: all 300ms ease;
  font-family: "bebas-kai", sans-serif;
  font-size: 4em;
  cursor: pointer;
  text-shadow: 0 0 8px #00000044;
  transform: rotate(180deg);
  transition: all 300ms ease;
  white-space: nowrap;
  writing-mode: vertical-lr;
  text-orientation: sideways;
}
.submenu-item.collapsed {
  min-width: 0;
  max-width: 0;
  overflow: hidden;
  transition: all .5s ease-out;
}
.submenu-item.toggled h2 {
  font-size: 2.3em;
  letter-spacing: .1em;
  position: relative;
  height: min-content;
}
.submenu-item:not(.toggled):hover h2 {
  letter-spacing: .15em;
  position: relative;
  height: min-content;
  /* text-indent: .4em; */
}
.submenu-item.toggled h2::after {
  content: '';
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #fff;
  filter: drop-shadow(0 0 8px #00000033);
}
.submenu-item.toggled h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -30px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #fff;
  filter: drop-shadow(0 0 8px #00000033);
}
.submenu-item:not(.toggled):hover h2::after {
  content: '';
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #fff;
  filter: drop-shadow(0 0 8px #00000033);
}
.submenu-item:not(.toggled):hover h2::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -35px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #fff;
  filter: drop-shadow(0 0 8px #00000033);
}

.about-langs > a {
  text-decoration: none;
}

.contentdiv {
  display: flex;
  flex-direction: column;
  opacity: 1;
  height: 100%;
  color: #ededed;
  padding: 30px var(--py-20);
  transition: height 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition-property: height, padding, opacity, min-height, max-height;
}
.contentdiv.hidden {
  opacity: 0;
  height: 0;
  max-height: 0;
  min-height: 0;
  overflow: hidden;
  padding: 0;
}

.subcontentdiv {
  display: flex;
  flex-direction: column;
  opacity: 1;
  height: 100%;
  width: 100%;
  color: #ededed;
  padding: 30px var(--py-20);
  padding-left: 0;
  transition: width 300ms ease;
  transition-property: opacity;
}
.subcontentdiv.hidden {
  opacity: 0;
  width: 0;
  max-width: 0;
  min-width: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: width 300ms ease;
  transition-property: opacity;
}
#music.toggled .contentdiv,
#coding.toggled .contentdiv,
#art.toggled .contentdiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#contact .contentdiv {
  width: 80vw;
}

.nav {
  min-height: 40px;
}
.tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.outside-tab {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.inside-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: hidden;
  padding-left: 0;
}

#about {
  /* background-color: #63b1d0;
  background-image: linear-gradient(0deg, transparent 50%, rgba(255,255,255,.08) 30%);
  background-size: 80px 80px; */
  background-image: linear-gradient(0deg, #791414 0%, #a72020 100%);
}
#work {
  /* background-color: #efbd5a;
  background-image: linear-gradient(0deg, transparent 50%, rgba(255,255,255,.11) 30%);
  background-size: 100px 100px; */
  background-image: linear-gradient(0deg, #bdbdbd 0%, #adadad 100%);
}
#work::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity .3s ease-in;
  /* background-image: linear-gradient(0deg, #791414 0%, #a72020 100%); */
}
#work.toggled::before {
  opacity: 1;
}
#contact {
  /* background-color: #f36967; */
  /* background-image: linear-gradient(0deg, transparent 50%, rgba(255,255,255,.08) 30%); */
  /* background-size: 130px 130px; */
  background-image: linear-gradient(0deg, #0f0f0f 0%, #1d1d1d 100%);
}
#music, #work.music::before {
  background-image: linear-gradient(90deg, #ff9a1f 0%, #ffc831 100%);
}
#art, #work.art::before {
  background-image: linear-gradient(90deg, #653885 0%, #451f56 100%);
}
#coding, #work.coding::before {
  background-image: linear-gradient(90deg, #750f0f 0%, #5a1010 100%);
}

#work.toggled > h1 {
  margin-top: 10px;
}

#work.toggled {
  justify-content: flex-start;
}
#about.toggled .contentdiv,
#contact.toggled .contentdiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.menuitem.toggled {
  /*height: 100%;*/
  transition: all 300ms ease;
}
#work.fullscreen {
  max-height: 100vh;
  min-height: 100vh;
  transition: all 300ms ease;
}
.menuitem.collapsed {
  max-height: 0;
  min-height: 0;
  overflow: hidden;
  transition: all 300ms ease;
}

#content-about {
  padding-left: var(--py-40);
  padding-right: var(--py-40);
}

#content-about p.lead {
  font-size: 1.125rem;
}

#content-about > p {
  /* color: #000000;
  text-shadow: 0 0 3px #00000099; */
}

#content-work {
  color: #ffffff;
}

#page-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 99999;
  opacity: 1;
  pointer-events: none;
  transition: all 300ms ease-out;
}
#page-loading.loading {
  opacity: 1;
  pointer-events: all;
}
#page-loading.loaded {
  opacity: 0;
  pointer-events: none;
}
#work-music .donations > a > img {
  width: 100%;
  max-width: 156px;
}

.foliorow, .tech-grid {
  margin-top: 5px;
  margin-bottom: 0;
}

.foliorow .col-4 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.foliocol {
  height: 90px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.moreon {
  margin-bottom: 10px;
}

/* #pills-music {
  position: relative;
}

.scroll-indicator {
  position: absolute;
  bottom: 0;
  height: 120px;
  width: 100%;
  margin-top: -120px;
  z-index: 2;
  -webkit-box-shadow: inset 0 -80px 24px -30px #000000ff;
  -moz-box-shadow: inset 0 -80px 24px -30px #000000ff;
  box-shadow: inset 0 -80px 24px -30px #000000ff;
}

.tb-shadow {
  -webkit-box-shadow: inset 0 -80px 24px -30px #000000ff;
  -moz-box-shadow: inset 0 -80px 24px -30px #000000ff;
  box-shadow: inset 0 -80px 24px -30px #000000ff;
  /* e7cc5eff
} */

#work\#art .moreon {
  margin-top: 10px;
}
.artfolio, .techfolio {
  transition: all 200ms ease;
  /*-webkit-transition: all 0.3s ease;*/
  background-size: 100%;
  background-position: center;
}

.subcontentdiv.transitioning,
.subcontentdiv.transitioning {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.submenu-item.opening h2 {
  margin-right: auto;
}

/* #work.toggled .contentdiv {
  max-height: calc(100% - var(--h1-height, 0));
} */

#coding .subcontentdiv {
  padding-top: 10px;
}

.artfolio {
  height: 140px;
  margin-top: 5px;
  display: block;
}
.techfolio {
  height: 200px;
  margin-top: 10px;
  margin-bottom: 0;
  width: auto;
}
[id^="techfolioCard"].techfolio {
  background-image: var(--imageSrc);
  background-size: 100% auto;
  aspect-ratio: 1.83 / 1;
}
.artfolio:hover, [id^="techfolioCard"].techfolio:hover {
  background-size: 110% auto;
}

#artfolio1 {
background-image: url("../img/art/art1.jpg");
}
#artfolio2 {
background-image: url("../img/art/art5.jpg");
}
#artfolio3 {
background-image: url("../img/art/art3.jpg");
}
#artfolio4 {
background-image: url("../img/art/art44.jpg");
background-position: top center;
}
#artfolio5 {
background-image: url("../img/art/art11.jpg");
}
#artfolio6 {
background-image: url("../img/art/art6.jpg");
}
#artfolio7 {
background-image: url("../img/art/art77.jpg");
}
#artfolio8 {
background-image: url("../img/art/art8.jpg");
}
#artfolio9 {
background-image: url("../img/art/art9.jpg");
}
#artfolio12 {
background-image: url("../img/art/art12.jpg");
}
#artfolio13 {
background-image: url("../img/art/art13.jpg");
}
#artfolio15 {
background-image: url("../img/art/art15.jpg");
}
#artfolio16 {
background-image: url("../img/art/art16.jpg");
background-position: 50% 40%;
}
#artfolio17 {
background-image: url("../img/art/art17.jpg");
}
#artfolio18 {
background-image: url("../img/art/art18.jpg");
background-size: auto 100%;
}
#artfolio18:hover {
  background-size: auto 110%;
}
.techfolio-card-10.techfolio, .techfolio-card-14.techfolio,
.techfolio-card-16.techfolio, .techfolio-card-17.techfolio,
.techfolio-card-18.techfolio, .techfolio-card-19.techfolio {
  background-size: 110% auto;
}
.techfolio-card-10.techfolio:hover, .techfolio-card-14.techfolio:hover,
.techfolio-card-16.techfolio:hover, .techfolio-card-17.techfolio:hover,
.techfolio-card-18.techfolio:hover, .techfolio-card-19.techfolio:hover {
  background-size: 120% auto;
}

.nav-pills {
  background-color: #8a8a8a;
  border-radius: 5px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #666666;
  border-radius: 5px;
}

.nav-pills .nav-link {
  color: #f5f5f5;
}

.btn-social, .email-link {
  color: #ffffff;
}

.btn-social:hover, .btn-social:active, .btn-social:focus,
.email-link:hover, .email-link:active, .email-link:focus {
  color: #007bff;
  text-decoration: none;
}

.art-grid-wrapper {
  overflow: hidden;
  max-height: 600px;
  position: relative;
}
.art-grid {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
}

.tech-grid-wrapper {
  position: relative;
  overflow: hidden;
  max-height: 80%;
}
.tech-grid-wrapper::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0; right: 0;
  height: 30px;
  background-image: linear-gradient(0deg, #740909 20%, #750f0f00 100%);
}

.tech-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: calc(var(--tech-gap) * -1);
  padding-top: 10px;
  padding-left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
}
.tech-grid > * {
  margin: var(--tech-gap);
}
.card-tech {
  flex: 1 1 auto;
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 0;
  width: calc((100% / var(--tech-cols)) - 2 * var(--tech-gap));
  padding-left: 0;
  padding-right: 0;
  position: relative;
  background-color: rgba(190, 30, 45, .1);
  backdrop-filter: blur(10px) grayscale(50%);
  -webkit-backdrop-filter: blur(10px) grayscale(50%);
  background-blend-mode: hard-light;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.4);
  /*mix-blend-mode: overlay;*/
}
.card-text {
  color: #ffffff;
  text-shadow: 1px 1px 4px rgba(0,0,0,0.33);
  /*filter: drop-shadow(0 0 6px rgba(0,0,0,0.35));*/
  font-weight: 300;
}
.card-text > a {
  text-shadow: 1px 1px 4px rgba(246,251,255,0.125);
  /*text-shadow: none;*/
  font-weight: 400;
}
.card-tech > .card-img-top {
  margin-top: 0;
  padding-top: 0;
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(60vw / (var(--tech-cols) * 1.25));
  padding-bottom: 50%;
}

.card-tech .card-body a {
  color: #ffbc50;
  text-decoration: underline;
  transition: all .2s ease-out;
}
.card-tech .card-body a:hover,
.card-tech .card-body a:active,
.card-tech .card-body a:focus {
  color: #dba542;
  text-decoration: none;
}
.donations {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 100%;
}
.donations > a {
  margin-left: 1rem;
  margin-right: 1rem;
  display: block;
}
.donations > a > img {
  width: 100%;
  max-width: 128px;
}
.btc-address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btc-address-qr {
  max-height: 128px;
  margin-bottom: 1rem;
  border: 3px solid #fff;
}
.btc-ribbon {
  max-height: 36px;
}
.donate-paypal > img {
  max-width: 286px;
  height: auto;
}

.lang-chart {
  position: relative;
  flex-grow: 2;
  height: auto;
}
.chart {
  position: relative;
  width: 100%;
  min-height: 128px;
  max-height: 100%;
}
.chart > embed {
  height: 100%;
  max-height: 200px;
}
@media screen and (max-width: 340px) {
  :root {
    --py-40: 10px;
    --py-20: 5px;
    --tech-cols: 1;
    --tech-gap: 5px;
  }
  #content-about p.lead {
    font-size: 0.875rem;
  }
  #work-music .donations > a > img {
    width: 100%;
    max-width: 118px;
  }
  #content-contact .btn {
    padding: .3rem .5rem;
  }
  #content-contact .fa-3x {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 500px) {
  .menuitem h1 {
    font-size: 3em;
  }
  .submenu-item h2 {
    font-size: 2em;
  }
  .submenu-item:not(.toggled):hover h2 {
    letter-spacing: .4rem;
  }
  .submenu-item.toggled h2 {
    font-size: 1rem;
    text-indent: 0;
  }
  .webswitcher a {
    font-size: 1rem;
  }
  .btc-address-qr {
    max-height: 72px;
  }
  .btc-ribbon {
    max-height: 24px;
  }
  .btn.btn-social > i.fab.fa-3x {
    font-size: 2rem !important;
  }
}
@media screen and (min-width: 576px) {
  :root {
    --tech-cols: 2;
  }
  #content-about p.lead {
    font-size: 1.25rem;
  }
  #work-music .donations > a > img {
    width: 100%;
    max-width: 200px;
  }
}
@media screen and (min-width: 960px) {
  :root {
    --tech-gap: 10px;
  }
  #content-about p.lead {
    font-size: 1.25rem;
  }
  #work-music .donations > a > img {
    width: 100%;
    max-width: 200px;
  }
  .card-tech > .card-img-top {
    /*height: 256px;*/
  }
}
@media screen and (min-width: 1280px) {
  :root {
    --py-40: 40px;
    --py-20: 20px;
    --tech-cols: 3;
  }
  #content-about p.lead {
    font-size: 1.5rem;
  }
  .artfolio {
    height: 200px;
  }
  #content-contact .fa-3x {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1440px) {
  :root {
    --tech-cols: 4;
    --tech-gap: 5px;
  }
  #content-about p.lead {
    font-size: 1.5rem;
  }
  .artfolio {
    height: 200px;
  }
  #content-contact .fa-3x {
    font-size: 5rem;
  }
}
@media screen and (min-width: 1920px) {
  :root {
    --py-40: 60px;
    --py-20: 30px;
    --tech-cols: 5;
    --tech-gap: 10px;
  }
  #work-music .donations > a > img {
    width: 100%;
    max-width: 300px;
  }
  #content-about p.lead {
    font-size: 2rem;
  }
  .artfolio {
    height: 250px;
  }
  #content-contact .fa-3x {
    font-size: 6rem;
  }
}
