:root {
  --py-40: 20px;
  --py-20: 10px;
  --tech-cols: 1;
  --tech-gap: 10px;
}

html, body {
  height: 100%;
  margin: 0;
}

a {
  transition: all .3s;
}

.webswitcher {
  z-index: 999;
  position: absolute;
  top: 10px;
  left: 10px;
}

.webswitcher a {
  color: #000;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
}

.webswitcher a:hover {
  opacity: 1;
  text-decoration: none;
}

.locale {
  left: unset;
  cursor: pointer;
  right: 10px;
}

.webswitcher.locale a.locale-active {
  opacity: .6;
  font-weight: 700;
}

.webswitcher.locale a.locale-active:hover {
  opacity: 1;
}

.locale-divider {
  opacity: .5;
  font-size: 1.5rem;
}

.mainmenu {
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.menuitem h1 {
  color: #f5f5f5;
  letter-spacing: 0;
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: 0 0 8px #0004;
  width: 100%;
  font-family: bebas-kai, sans-serif;
  font-size: 4em;
  transition: all .3s;
}

.menuitem.toggled h1 {
  letter-spacing: .1em;
  width: min-content;
  margin-top: 20px;
  font-size: 2.5em;
  position: relative;
}

.menuitem:not(.toggled):hover h1 {
  letter-spacing: .4em;
  text-indent: .4em;
  width: min-content;
  position: relative;
}

.menuitem.toggled h1:after {
  content: "";
  filter: drop-shadow(0 0 8px #0005);
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 10px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}

.menuitem.toggled h1:before {
  content: "";
  filter: drop-shadow(0 0 8px #0005);
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 10px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
}

.menuitem:not(.toggled):hover h1:after {
  content: "";
  filter: drop-shadow(0 0 8px #0005);
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-right: 20px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
}

.menuitem:not(.toggled):hover h1:before {
  content: "";
  filter: drop-shadow(0 0 8px #0005);
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  border-left: 20px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}

.menuitem {
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  max-height: 100vh;
  transition: all .3s;
  display: flex;
  position: relative;
  box-shadow: inset 0 3px 5px -3px #000000bf;
}

.menuitem:hover {
  flex-grow: 1.15;
}

.menuitem.toggled {
  flex-grow: 10;
}

.submenu {
  flex-direction: row;
  width: 100vw;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.submenu-item {
  text-align: center;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-width: 10vw;
  max-width: 100vw;
  height: 100%;
  transition: all .3s;
  display: flex;
  box-shadow: inset 3px 0 5px -3px #000000bf, inset 0 3px 5px -3px #000000bf;
}

.submenu-item:hover {
  flex-grow: 1.15;
}

.submenu-item.toggled {
  flex-grow: 15;
  min-width: 100vw;
}

.submenu-item h2 {
  color: #f5f5f5;
  letter-spacing: 0;
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: 0 0 8px #0004;
  white-space: nowrap;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  font-family: bebas-kai, sans-serif;
  font-size: 4em;
  transition: all .3s;
  transform: rotate(180deg);
}

.submenu-item.collapsed {
  min-width: 0;
  max-width: 0;
  transition: all .5s ease-out;
  overflow: hidden;
}

.submenu-item.toggled h2 {
  letter-spacing: .1em;
  height: min-content;
  font-size: 2.3em;
  position: relative;
}

.submenu-item:not(.toggled):hover h2 {
  letter-spacing: .15em;
  height: min-content;
  position: relative;
}

.submenu-item.toggled h2:after {
  content: "";
  filter: drop-shadow(0 0 8px #0003);
  border-top: 10px solid #fff;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu-item.toggled h2:before {
  content: "";
  filter: drop-shadow(0 0 8px #0003);
  border-bottom: 10px solid #fff;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu-item:not(.toggled):hover h2:after {
  content: "";
  filter: drop-shadow(0 0 8px #0003);
  border-top: 20px solid #fff;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu-item:not(.toggled):hover h2:before {
  content: "";
  filter: drop-shadow(0 0 8px #0003);
  border-bottom: 20px solid #fff;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
}

.about-langs > a {
  text-decoration: none;
}

.contentdiv {
  opacity: 1;
  color: #ededed;
  height: 100%;
  padding: 30px var(--py-20);
  flex-direction: column;
  transition: height .3s cubic-bezier(.6, -.28, .735, .045), padding .3s cubic-bezier(.6, -.28, .735, .045), opacity .3s cubic-bezier(.6, -.28, .735, .045), min-height .3s cubic-bezier(.6, -.28, .735, .045), max-height .3s cubic-bezier(.6, -.28, .735, .045);
  display: flex;
}

.contentdiv.hidden {
  opacity: 0;
  height: 0;
  min-height: 0;
  max-height: 0;
  padding: 0;
  overflow: hidden;
}

.subcontentdiv {
  opacity: 1;
  color: #ededed;
  width: 100%;
  height: 100%;
  padding: 30px var(--py-20);
  flex-direction: column;
  padding-left: 0;
  transition: opacity .3s;
  display: flex;
}

.subcontentdiv.hidden {
  opacity: 0;
  width: 0;
  min-width: 0;
  max-width: 0;
  height: 0;
  padding: 0;
  transition: opacity .3s;
  overflow: hidden;
}

#music.toggled .contentdiv, #coding.toggled .contentdiv, #art.toggled .contentdiv {
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  display: flex;
}

#contact .contentdiv {
  width: 80vw;
}

.nav {
  min-height: 40px;
}

.tab-content {
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden auto;
}

.outside-tab {
  flex-direction: column;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.inside-tab {
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding-left: 0;
  display: flex;
  overflow: hidden;
}

#about {
  background-image: linear-gradient(0deg, #791414 0%, #a72020 100%);
}

#work {
  background-image: linear-gradient(0deg, #bdbdbd 0%, #adadad 100%);
}

#work:before {
  content: "";
  opacity: 0;
  transition: opacity .3s ease-in;
  position: absolute;
  inset: 0;
}

#work.toggled:before {
  opacity: 1;
}

#contact {
  background-image: linear-gradient(0deg, #0f0f0f 0%, #1d1d1d 100%);
}

#music, #work.music:before {
  background-image: linear-gradient(90deg, #ff9a1f 0%, #ffc831 100%);
}

#art, #work.art:before {
  background-image: linear-gradient(90deg, #653885 0%, #451f56 100%);
}

#coding, #work.coding:before {
  background-image: linear-gradient(90deg, #750f0f 0%, #5a1010 100%);
}

#work.toggled > h1 {
  margin-top: 10px;
}

#work.toggled {
  justify-content: flex-start;
}

#about.toggled .contentdiv, #contact.toggled .contentdiv {
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  display: flex;
}

.menuitem.toggled {
  transition: all .3s;
}

#work.fullscreen {
  min-height: 100vh;
  max-height: 100vh;
  transition: all .3s;
}

.menuitem.collapsed {
  min-height: 0;
  max-height: 0;
  transition: all .3s;
  overflow: hidden;
}

#content-about {
  padding-left: var(--py-40);
  padding-right: var(--py-40);
}

#content-about p.lead {
  font-size: 1.125rem;
}

#content-work {
  color: #fff;
}

#page-loading {
  z-index: 99999;
  opacity: 1;
  pointer-events: none;
  background-color: #fff;
  transition: all .3s ease-out;
  position: fixed;
  inset: 0;
}

#page-loading.loading {
  opacity: 1;
  pointer-events: all;
}

#page-loading.loaded {
  opacity: 0;
  pointer-events: none;
}

#work-music .donations > a > img {
  width: 100%;
  max-width: 156px;
}

.foliorow, .tech-grid {
  margin-top: 5px;
  margin-bottom: 0;
}

.foliorow .col-4 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.foliocol {
  height: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.moreon {
  margin-bottom: 10px;
}

#work\#art .moreon {
  margin-top: 10px;
}

.artfolio, .techfolio {
  background-position: center;
  background-size: 100%;
  transition: all .2s;
}

.subcontentdiv.transitioning, .subcontentdiv.transitioning {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.submenu-item.opening h2 {
  margin-right: auto;
}

#coding .subcontentdiv {
  padding-top: 10px;
}

.artfolio {
  height: 140px;
  margin-top: 5px;
  display: block;
}

.techfolio {
  width: auto;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 0;
}

[id^="techfolioCard"].techfolio {
  background-image: var(--imageSrc);
  aspect-ratio: 1.83;
  background-size: 100%;
}

.artfolio:hover, [id^="techfolioCard"].techfolio:hover {
  background-size: 110%;
}

#artfolio1 {
  background-image: url("art1.e9a73efe.jpg");
}

#artfolio2 {
  background-image: url("art5.694fc8fd.jpg");
}

#artfolio3 {
  background-image: url("art3.4d057129.jpg");
}

#artfolio4 {
  background-image: url("art44.10d73407.jpg");
  background-position: top;
}

#artfolio5 {
  background-image: url("art11.38ef836c.jpg");
}

#artfolio6 {
  background-image: url("art6.8643aca6.jpg");
}

#artfolio7 {
  background-image: url("art77.33b47ab3.jpg");
}

#artfolio8 {
  background-image: url("art8.512e7540.jpg");
}

#artfolio9 {
  background-image: url("art9.846f218d.jpg");
}

#artfolio12 {
  background-image: url("art12.476d67f7.jpg");
}

#artfolio13 {
  background-image: url("art13.800a47c7.jpg");
}

#artfolio15 {
  background-image: url("art15.3d825efd.jpg");
}

#artfolio16 {
  background-image: url("art16.7b551fae.jpg");
  background-position: 50% 40%;
}

#artfolio17 {
  background-image: url("art17.56273d90.jpg");
}

#artfolio18 {
  background-image: url("art18.afd19241.jpg");
  background-size: auto 100%;
}

#artfolio18:hover {
  background-size: auto 110%;
}

.techfolio-card-10.techfolio, .techfolio-card-14.techfolio, .techfolio-card-16.techfolio, .techfolio-card-17.techfolio, .techfolio-card-18.techfolio, .techfolio-card-19.techfolio {
  background-size: 110%;
}

.techfolio-card-10.techfolio:hover, .techfolio-card-14.techfolio:hover, .techfolio-card-16.techfolio:hover, .techfolio-card-17.techfolio:hover, .techfolio-card-18.techfolio:hover, .techfolio-card-19.techfolio:hover {
  background-size: 120%;
}

.nav-pills {
  background-color: #8a8a8a;
  border-radius: 5px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #666;
  border-radius: 5px;
}

.nav-pills .nav-link {
  color: #f5f5f5;
}

.btn-social, .email-link {
  color: #fff;
}

.btn-social:hover, .btn-social:active, .btn-social:focus, .email-link:hover, .email-link:active, .email-link:focus {
  color: #007bff;
  text-decoration: none;
}

.art-grid-wrapper {
  max-height: 600px;
  position: relative;
  overflow: hidden;
}

.art-grid {
  max-height: 100%;
  overflow: hidden scroll;
}

.tech-grid-wrapper {
  max-height: 80%;
  position: relative;
  overflow: hidden;
}

.tech-grid-wrapper:after {
  content: "";
  background-image: linear-gradient(0deg, #740909 20%, #750f0f00 100%);
  height: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tech-grid {
  padding: calc(var(--tech-gap) * -1);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  max-height: 100%;
  padding-top: 10px;
  padding-left: 0;
  display: flex;
  overflow: hidden scroll;
}

.tech-grid > * {
  margin: var(--tech-gap);
}

.card-tech {
  width: calc((100% / var(--tech-cols))  - 2 * var(--tech-gap));
  -webkit-backdrop-filter: blur(10px) grayscale(50%);
  background-blend-mode: hard-light;
  background-color: #be1e2d1a;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  flex: auto;
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  box-shadow: 0 0 5px -3px #0006;
}

.card-text {
  color: #fff;
  text-shadow: 1px 1px 4px #00000054;
  font-weight: 300;
}

.card-text > a {
  text-shadow: 1px 1px 4px #f6fbff20;
  font-weight: 400;
}

.card-tech > .card-img-top {
  width: 100%;
  height: calc(60vw / (var(--tech-cols) * 1.25));
  background-repeat: no-repeat;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 50%;
  position: relative;
}

.card-tech .card-body a {
  color: #ffbc50;
  text-decoration: underline;
  transition: all .2s ease-out;
}

.card-tech .card-body a:hover, .card-tech .card-body a:active, .card-tech .card-body a:focus {
  color: #dba542;
  text-decoration: none;
}

.donations {
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.donations > a {
  margin-left: 1rem;
  margin-right: 1rem;
  display: block;
}

.donations > a > img {
  width: 100%;
  max-width: 128px;
}

.btc-address {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btc-address-qr {
  border: 3px solid #fff;
  max-height: 128px;
  margin-bottom: 1rem;
}

.btc-ribbon {
  max-height: 36px;
}

.donate-paypal > img {
  max-width: 286px;
  height: auto;
}

.lang-chart {
  flex-grow: 2;
  height: auto;
  position: relative;
}

.chart {
  width: 100%;
  min-height: 128px;
  max-height: 100%;
  position: relative;
}

.chart > embed {
  height: 100%;
  max-height: 200px;
}

@media screen and (width <= 340px) {
  :root {
    --py-40: 10px;
    --py-20: 5px;
    --tech-cols: 1;
    --tech-gap: 5px;
  }

  #content-about p.lead {
    font-size: .875rem;
  }

  #work-music .donations > a > img {
    width: 100%;
    max-width: 118px;
  }

  #content-contact .btn {
    padding: .3rem .5rem;
  }

  #content-contact .fa-3x {
    font-size: 2.5rem;
  }
}

@media screen and (width <= 500px) {
  .menuitem h1 {
    font-size: 3em;
  }

  .submenu-item h2 {
    font-size: 2em;
  }

  .submenu-item:not(.toggled):hover h2 {
    letter-spacing: .4rem;
  }

  .submenu-item.toggled h2 {
    text-indent: 0;
    font-size: 1rem;
  }

  .webswitcher a {
    font-size: 1rem;
  }

  .btc-address-qr {
    max-height: 72px;
  }

  .btc-ribbon {
    max-height: 24px;
  }

  .btn.btn-social > i.fab.fa-3x {
    font-size: 2rem !important;
  }
}

@media screen and (width >= 576px) {
  :root {
    --tech-cols: 2;
  }

  #content-about p.lead {
    font-size: 1.25rem;
  }

  #work-music .donations > a > img {
    width: 100%;
    max-width: 200px;
  }
}

@media screen and (width >= 960px) {
  :root {
    --tech-gap: 10px;
  }

  #content-about p.lead {
    font-size: 1.25rem;
  }

  #work-music .donations > a > img {
    width: 100%;
    max-width: 200px;
  }
}

@media screen and (width >= 1280px) {
  :root {
    --py-40: 40px;
    --py-20: 20px;
    --tech-cols: 3;
  }

  #content-about p.lead {
    font-size: 1.5rem;
  }

  .artfolio {
    height: 200px;
  }

  #content-contact .fa-3x {
    font-size: 4rem;
  }
}

@media screen and (width >= 1440px) {
  :root {
    --tech-cols: 4;
    --tech-gap: 5px;
  }

  #content-about p.lead {
    font-size: 1.5rem;
  }

  .artfolio {
    height: 200px;
  }

  #content-contact .fa-3x {
    font-size: 5rem;
  }
}

@media screen and (width >= 1920px) {
  :root {
    --py-40: 60px;
    --py-20: 30px;
    --tech-cols: 5;
    --tech-gap: 10px;
  }

  #work-music .donations > a > img {
    width: 100%;
    max-width: 300px;
  }

  #content-about p.lead {
    font-size: 2rem;
  }

  .artfolio {
    height: 250px;
  }

  #content-contact .fa-3x {
    font-size: 6rem;
  }
}
/*# sourceMappingURL=index.0f16de16.css.map */
